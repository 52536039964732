import Cards from './components/Cards'

function App() {
  return (
    <div className="App">
      <h1>Memory Game</h1>
      <Cards />
    </div>
  );
}

export default App;